import { useMemo } from "react";
import Portal from "./components/portal";
import CodeViewer from "./components/codeViewer";
import queryString from "query-string";

export default function App() {
  const code = useMemo(() => {
    try {
      const {code} =  queryString.parse(window.location.search);
      if(typeof code === 'string') return code;
      return '';
    } catch (e) {
      console.error(e);
      return "";
    }
  }, []);
  return (
    <Portal>
      <CodeViewer initialCode={code} />
    </Portal>
  );
}
