import { ReactNode } from "react";
import "./style.scss";

export default function Portal({ children }: { children: ReactNode }) {
  return (
    <div className="portal">
      <header>
        <nav>
          <div className="container">尖刀盾文化</div>
        </nav>
      </header>
      <main>
        <div className="header">
          <div className="header-bg">
            <span className="span-150"></span>
            <span className="span-50"></span>
            <span className="span-50"></span>
            <span className="span-75"></span>
            <span className="span-100"></span>
            <span className="span-75"></span>
            <span className="span-50"></span>
            <span className="span-100"></span>
            <span className="span-50"></span>
            <span className="span-100"></span>
          </div>
          <div className="container">
            <h1>高考志愿填报指南</h1>
            <p>
              欢迎使用高考志愿填报指南服务！我们致力于帮助高考生更精准地选择适合自己的大学和专业，让您的高考成绩发挥最大的价值。
            </p>
          </div>
          <div className="header-tail">
            <svg
              xmlns="http://www.w3.org/1500/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 150"
              x="0"
              y="0"
            >
              <polygon points="2560 0 2560 150 0 150"></polygon>
            </svg>
          </div>
        </div>

        <div className="container input">{children}</div>
        <div className="container body">
          <p className="title">产品介绍</p>
          <p>
            高考志愿填报指南是一款专为高考生设计的志愿填报辅助工具。我们的产品基于最新的数据和算法，提供以下核心功能：
          </p>
          <ul>
            		<li>
              		录取分数线预估：根据最新的高考数据，预估指定大学在今年的录取分数线，帮助您更好地判断录取概率。
            		</li>
            		<li>
              		录取位次预估：根据考生省排名的位次，预估考生在投档该院校时的位次，作为考生选填专业的参考。
            		</li>
            		<li>
              		历史数据分析：分析历年的录取数据，提供趋势预测，帮助您更好地理解各校录取情况。
            		</li>
          </ul>
          <p>
            通过高考志愿填报指南，您可以更轻松地选择心仪的大学，避免盲目填报，提高录取成功率。
          </p>
        </div>
      </main>
      <footer className="container">
        <hr />
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          ICP备案号：陕ICP备2024040979号-1
        </a>
        <br/>
        <br/>
        <img src="./static/img/备案图标.png"  height="14px" alt="备案图标"  />&nbsp;
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=61011602000653" rel="noreferrer" target="_blank" line-height="14px" >公安备案号：陕公网安备61011602000653</a>
      </footer>
    </div>
  );
}
