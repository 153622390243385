import { useCallback, useEffect, useMemo, useState } from "react";
import { IUserModel } from "../../interfaces";
import "./style.scss";

export default function CodeViewer({ initialCode }: { initialCode?: string }) {
  const [user, setUser] = useState<IUserModel | undefined>();
  const [code, setCode] = useState(initialCode || "");
  const [isVerifying, setIsVerifying] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const onVerifyCode = useCallback(async () => {
    if (!code.trim()) return;
    if (isVerifying) return;
    setIsVerifying(true);
    try {
      const res = await fetch(`/verify?code=${code.trim()}`);
      // const res = await fetch(`/static/${code.trim()}`);
      const data: IUserModel = await res.json();
      if (res.status !== 200) throw new Error(JSON.stringify(data));
      setUser(data);
    } catch (e) {
      console.error(e);
      setVerifyError(true);
    }
    setIsVerifying(false);
  }, [code, isVerifying]);

  useEffect(() => {
    onVerifyCode();
  }, []);

  const btnDisabled = useMemo(
    () => isVerifying || verifyError || code.trim().length === 0,
    [isVerifying, verifyError, code]
  );

  if (user) {
    return (
      <div className="code-wrapper success">
        <h2>
          <span>{user.name}</span>，恭喜！您的产品已通过尖刀盾文化认证！
        </h2>
      </div>
    );
  }

  return (
    <div className="code-wrapper">
      <div className="code">
        <input
          style={{ width: btnDisabled ? "100%" : "calc(100% - 50px)" }}
          placeholder="请输入产品验证码"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
            setVerifyError(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onVerifyCode();
            }
          }}
        />
        {!btnDisabled && (
          <button disabled={btnDisabled} onClick={onVerifyCode}>
            验证
          </button>
        )}
      </div>
      {verifyError && <p className="code-error">验证失败，请确认验证码正确</p>}
    </div>
  );
}
